import React, { PureComponent } from 'react';
import { Tag, Form, Input } from 'antd';
import globalUserStore from '@/global_store/globalUserStore';
import theme from '@/config/theme';
import { Button, Dialog, Layout } from '@/components';
import './user_info.less';
import { FormInstance } from 'antd/lib/form';
import WebApi from '@/web/api';
import md5 from '@/utils/md5';

const bgColors = [
  theme['primary-color'],
  '#68A6F6',
  '#38D5B0',
  '#FF948F',
  '#58C8D0',
  // '#F4CE65',
];

interface IState {
  visible: boolean;
}
export default class CompanyPersonalEdit extends PureComponent<{}, IState> {
  private formRef = React.createRef<FormInstance>();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  render() {
    return <Layout.Page height className="user-info-page">
      <div className="user-info-page-content">
        <div className="user-info-page-content-header">
          <div className="header-photo-icon">
            <i className="iconfont icon-icon-user-img"/>
          </div>
          <div>
            <p>姓名：{globalUserStore.fullName}</p>
            <p>登录账号：{globalUserStore.mobile}</p>
            <p>账号ID：{globalUserStore.userId}</p>
            <p>所在部门：{globalUserStore.departmentName}</p>
          </div>
        </div>

        <div className="user-info-page-content-body">
          <div className="body-item">
            <div className="body-item-left">
              <div className="body-item-icon">
                <i className="iconfont icon-mima" style={{fontSize: 36}}/>
              </div>
              <div className="body-item-label">登录密码</div>
            </div>
            <div className="body-item-right">
              <div className="body-item-right-desc">
                <p>安全性高的密码可以使帐号更安全。建议您定期更换密码，设置一个包含数字、大写字母、小写字母、特殊字符至少三项且长度8~18位的密码。</p>
              </div>
              <div className="body-item-right-button">
                <Button type="ghost" onClick={this.clickUpdatePassword}>修改密码</Button>
              </div>
            </div>
          </div>
          <div className="body-item">
            <div className="body-item-left">
              <div className="body-item-icon">
                <i className="iconfont icon-quanxianguanli" style={{fontSize: 36}}/>
              </div>
              <div className="body-item-label">账号权限</div>
            </div>
            <div className="body-item-right">
              {
                globalUserStore.roleList.map((item, index) => {
                  return <Tag key={index} color={bgColors[index]}>{item}</Tag>
                })
              }
            </div>
          </div>
        </div>
      </div>

      <Dialog title="修改密码" visible={this.state.visible}
        onCancel={this.clickUpdatePasswordCancel}
        full={false}
        onOkPro={this.clickUpdatePasswordOk}>
          <Form labelCol={{span: 5}} ref={this.formRef}>
            <Form.Item label="旧密码" name="old" rules={[
              {
                required: true,
                message: '请填写旧密码'
              }
            ]}>
              <Input.Password/>
            </Form.Item>
            <Form.Item label="新密码" name="new" rules={[
              {
                required: true,
                message: '请填写新密码'
              },{
                min: 6,
                message: '密码必须6-20位字符'
              },
              {
                max: 20,
                message: '密码必须6-20位字符'
              }
            ]}>
              <Input.Password/>
            </Form.Item>
            <Form.Item label="确认密码" name="confirm" rules={[
              {
                required: true,
                message: '请确认密码!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('两次密码填写不一致!'));
                },
              }),
            ]}>
              <Input.Password/>
            </Form.Item>
          </Form>
        </Dialog>
    </Layout.Page>
  }

  private clickUpdatePassword = () => {
    this.setState({visible: true})
  }

  private clickUpdatePasswordCancel = () => {
    this.setState({visible: false});
  }

  private clickUpdatePasswordOk = (setLoading) => {
    this.formRef.current?.validateFields().then(data => {
      WebApi.user_update_password({
        old: md5.hex_md5(data.old),
        new: md5.hex_md5(data.new),
      }).then(res => {
        setLoading(false);
        showSuccess.save();
        this.setState({
          visible: false,
        });
      }).catch(() => {
        setLoading(false);
      })
    })
  }
}
